import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../utils/Firebase";
import Select from "../../atoms/Select";
import CountryISOCodes from "../../../data/countryISOcodes.json";
import getCountryFlag from "../../../scripts/getCountryFlag";
import MinistryReportData from "./MinistryReportData";
import Divider from "../../atoms/Divider";
import MinistrySelect from "../../molecules/ministrySelect";
// import ResourceTable from "./atoms/ResourceTable";

const UserMinistries = ({ firebase }) => {
  // const { currentUser } = firebase ? firebase.auth : { currentUser: null };
  // const [userData, setUserData] = useState();
  const [ministryProfiles, setMinistryProfiles] = useState([]);
  // const [ministries, setMinistries] = useState([]);
  const [selectedMinistry, setSelectedMinistry] = useState();
  const [selectedMinistryData, setSelectedMinistryData] = useState();

  return (
    <div className="home container">
      <div className="ministry__details">
        <h1 className="ministry__title">Ministry Report</h1>
      </div>
      <MinistrySelect
        selectedMinistry={selectedMinistry}
        setSelectedMinistry={setSelectedMinistry}
        setSelectedMinistryData={setSelectedMinistryData}
      />
      <div className="ministry">
        {selectedMinistry && selectedMinistryData && (
          <MinistryReportData ministryData={selectedMinistryData} />
        )}
      </div>
    </div>
  );
};

export default withFirebase(UserMinistries);

import React from "react"
import { Bar } from "react-chartjs-2"

const createDataSet = (months, reportData) => {
  const dataSets = [
    {
      type: "line",
      label: "Bibles Shared",
      borderColor: "rgb(54, 162, 235)",
      borderWidth: 2,
      fill: false,
      data: [],
    },
    {
      type: "bar",
      label: "Locations",
      backgroundColor: "rgb(255, 99, 132)",
      data: [],
      borderColor: "white",
      borderWidth: 2,
    },
  ]
  months.forEach((m) => {
    console.log(m, reportData[m], reportData)
    const { bibles, accepted, delivered, have, need, unfinished, rejected } =
      reportData[m] || {}
    dataSets[0].data.push(bibles || 0)
    const totalLocations =
      (accepted || 0) +
      (delivered || 0) +
      (have || 0) +
      (need || 0) +
      (unfinished || 0) +
      (rejected || 0)
    dataSets[1].data.push(totalLocations)
  })
  return {
    labels: months,
    datasets: dataSets,
  }
}

const data = {
  datasets: [
    {
      label: "# of Red Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      label: "# of Blue Votes",
      data: [2, 3, 20, 5, 1, 4],
      backgroundColor: "rgb(54, 162, 235)",
    },
    {
      label: "# of Green Votes",
      data: [3, 10, 13, 15, 22, 30],
      backgroundColor: "rgb(75, 192, 192)",
    },
  ],
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
}

const StackedBar = ({ months, reportData, title }) => (
  <>
    {title && (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {title}
        </p>
      </div>
    )}
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        maxHeight: 250,
        marginBottom: 50,
      }}
    >
      <Bar
        data={createDataSet(months, reportData)}
        options={options}
        height={null}
        width={null}
      />
    </div>
  </>
)

export default StackedBar

import React, { Component } from "react"
import { Link } from "gatsby"
import { withFirebase } from "../../../utils/Firebase"
import Loading from "../../atoms/Loading"
import Divider from "../../atoms/Divider"
import {
  MINISTRY,
  RESOURCES,
  CREATE_USER,
  MAPS,
} from "../../../constants/routes"
import MinistryReport from "./MinistryReport"

class Home extends Component {
  _initFirebase = false

  state = {
    posts: [],
    loading: false,
    title: "",
    description: "",
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true

      this.setState({
        loading: false,
      })
    }
  }

  componentDidMount() {
    this.firebaseInit()
  }

  componentDidUpdate() {
    this.firebaseInit()
  }

  render() {
    const { loading } = this.state

    if (loading) return <Loading />

    return (
      <>
        <div className="home container">
          <div className="ministry__details">
            <h1 className="ministry__title">Dashboard</h1>
          </div>
          <div className="ministry__divider">
            <Divider />
          </div>
          <div className="home__content">
            <h1 className="home__title">Welcome to Share Bibles!</h1>
            <p>Hey Share Bibles Facilitators!</p>
            <p>
              This page is still being developed with information that might
              help you understand where you're distributing Bibles, what you're
              distributing and a few reports based on what you've done.
            </p>
            <p>
              If this is your first time here, I would recommend that you start
              with the following:
              <ul>
                <li>
                  <Link to={MINISTRY}>Update your ministry profile</Link>
                </li>
                <li>
                  <Link to={RESOURCES}>
                    Create the Bible resources that your ministry is
                    distributing
                  </Link>
                </li>
                <li>
                  <Link to={CREATE_USER}>
                    Create Teams or other facilitator accounts for your ministry
                  </Link>
                </li>
                <li>
                  <Link to={MAPS}>🗺️ - View regional pins on a Google map</Link>
                </li>
              </ul>
            </p>
            <p>
              If you have any questions or would like some help with something,
              please contact us:
            </p>
            <p>info@sharebibles.com</p>
          </div>
          <div className="ministry__divider">
            <Divider />
          </div>
        </div>
        <div>
          <MinistryReport />
        </div>
      </>
    )
  }
}

export default withFirebase(Home)

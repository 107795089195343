import React, { useEffect, useState } from "react"
import moment from "moment"
import { withFirebase } from "../../../utils/Firebase"
import HorizontalBarChart from "../Report/atoms/HorizontalBar"
import StackedBar from "../../atoms/StackedBar"
import Divider from "../../atoms/Divider"

// Consistent timestamp
const momentPrevHour = moment().utc()
// const momentPrevHour = moment().utc();
const momentPrevHourKey = moment(momentPrevHour)
  .utc()
  .local()
  .format("YYYY-MM-DD-HH")
const year = momentPrevHourKey.substring(0, 4)
const month = momentPrevHourKey.substring(0, 7)

const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
]

const monthIndex = months.indexOf(month.substring(5, 7))

const MinistryReportData = ({ firebase, ministryData }) => {
  const { ministryId, reportData } = ministryData
  const [report, setReport] = useState({})
  const [monthsArray, setMonthsArray] = useState([])

  const getReportObj = async () => {
    // create reportObj
    const reportObj = {
      [year]: {},
      [month]: {},
      overall: reportData || {},
    }

    await firebase
      .timeBasedReport(ministryId, year)
      .get()
      .then((doc) => {
        console.log(`${ministryId} ${year} report RETRIEVED`)
        reportObj[year] = doc.data()
      })
    if (year === "2021") {
      const monthsOptions = months
        .slice(0, monthIndex + 1)
        .map((m) => `${year}-${m}`)
        .reverse()
      setMonthsArray(monthsOptions.sort())
      monthsOptions.forEach(async (m) => {
        await firebase
          .timeBasedReport(ministryId, m)
          .get()
          .then((doc) => {
            reportObj[m] = doc.data()
          })
      })
    } else {
      const monthsOptions = [
        ...months
          .slice(0, monthIndex + 1)
          .map((m) => `${year}-${m}`)
          .reverse(),
        ...months
          .slice(monthIndex + 1)
          .map((m) => `${+year - 1}-${m}`)
          .reverse(),
      ]
      console.log(monthsOptions)
      setMonthsArray(monthsOptions.sort())
      monthsOptions.forEach(async (m) => {
        await firebase
          .timeBasedReport(ministryId, m)
          .get()
          .then((doc) => {
            reportObj[m] = doc.data()
          })
      })
    }
    await firebase
      .timeBasedReport(ministryId, year)
      .get()
      .then((doc) => {
        console.log(`${ministryId} ${year} report RETRIEVED`)
        reportObj[year] = doc.data()
      })

    setReport(reportObj)
  }

  useEffect(() => {
    if (firebase) {
      getReportObj()
    }
  }, [firebase, ministryData])

  if (report) {
    return (
      <>
        <div
          className="horizontalChart"
          style={{
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <HorizontalBarChart
            data={report.overall || {}}
            title={`Overall Total Distribution`}
          />
        </div>
        <div className="ministry__divider">
          <Divider />
        </div>
        <div
          className="horizontalChart"
          style={{
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <HorizontalBarChart
            data={report[year] || {}}
            title={`Distribution in ${year}`}
          />
        </div>
        <div className="ministry__divider">
          <Divider />
        </div>
        <div
          className="horizontalChart"
          style={{
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <StackedBar
            months={monthsArray}
            reportData={report || {}}
            title="Distribution by month"
          />
        </div>
      </>
    )
  }
  return null
}

export default withFirebase(MinistryReportData)
